body {
    margin: 0;
    font-family: 'Arial Rounded MT Bold', sans-serif;
  }
  
  .App {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    transition: background-color 0.5s ease;
  }
  

  .App.App.pomodoro {
    background-color: rgb(166, 53, 53);
  }
  
  .App.App.short-break {
    background-color: rgb(26, 93, 98);
  }
  
  .App.App.long-break {
    background-color: rgb(37, 92, 131);
  }